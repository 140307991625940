import { useDesignTokens } from '@/composables/useDesignTokens'

export default {
  install: (app) => {
    const { tokens, getColorHex, getColorRgb, getFontFamily, getFontWeight } = useDesignTokens()
    
    // Make tokens available globally
    app.config.globalProperties.$designTokens = {
      tokens,
      getColorHex,
      getColorRgb,
      getFontFamily,
      getFontWeight
    }
    
    // Provide tokens to the application
    app.provide('designTokens', {
      tokens,
      getColorHex,
      getColorRgb,
      getFontFamily,
      getFontWeight
    })
  }
}