const fontFamilies = {
  Grandview: [
    { weight: 300, style: 'normal', name: 'Grandview Light' },
    { weight: 400, style: 'normal', name: 'Grandview Regular' },
    { weight: 500, style: 'normal', name: 'Grandview Medium' },
    { weight: 700, style: 'normal', name: 'Grandview Bold' }
  ],
  'Kumbh Sans': [
    { weight: 400, style: 'normal', name: 'Kumbh Sans Regular' },
    { weight: 500, style: 'normal', name: 'Kumbh Sans Medium' },
    { weight: 600, style: 'normal', name: 'Kumbh Sans SemiBold' },
    { weight: 700, style: 'normal', name: 'Kumbh Sans Bold' }
  ],
  'Oswald': [
    { weight: 700, style: 'normal', name: 'Oswald Bold' }
  ]
}

/**
 * Loads fonts with improved error handling and timeout
 * @param {number} timeout - Timeout in milliseconds
 * @returns {Promise} - Promise that resolves when fonts are loaded or timeout occurs
 */
export function loadFonts(timeout = 2000) {
  const fonts = []

  Object.entries(fontFamilies).forEach(([family, variants]) => {
    variants.forEach(({ weight, style, name }) => {
      const fontPromise = new Promise((resolve) => {
        try {
          const font = new FontFace(name, `local("${family}")`, {
            weight,
            style
          })

          // Add timeout to prevent hanging
          const timeoutId = setTimeout(() => {
            // eslint-disable-next-line no-console
            console.warn(`Font loading timed out for: ${name}`)
            resolve() // Resolve anyway to prevent blocking the app
          }, timeout)

          font.load()
            .then(() => {
              clearTimeout(timeoutId)
              document.fonts.add(font)
              resolve()
            })
            .catch((err) => {
              clearTimeout(timeoutId)
              // eslint-disable-next-line no-console
              console.warn(`Failed to load font: ${name}`, err)
              resolve() // Resolve anyway to prevent blocking the app
            })
        } catch (err) {
          // Handle any synchronous errors in FontFace creation
          // eslint-disable-next-line no-console
          console.warn(`Error creating FontFace: ${name}`, err)
          resolve() // Resolve anyway to prevent blocking the app
        }
      })

      fonts.push(fontPromise)
    })
  })

  return Promise.all(fonts)
}