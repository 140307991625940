import md5 from 'md5'
import { encode } from 'gpt-tokenizer'
import { default as lodashIsEmpty } from 'lodash.isempty'
import { marked } from 'marked'

// Utility function for creating and caching Intl.NumberFormat instances
const numberFormatters = {}
const getMoneyFormatter = (locale, currency, decimals) => {
  const key = `${locale}-${currency}-${decimals}`
  if (!numberFormatters[key]) {
    numberFormatters[key] = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: decimals
    })
  }
  return numberFormatters[key]
}

// Formats a number as currency
const moneyFormat = (value, decimals = 2, currency = 'EUR', locale = 'en-US') => {
  return getMoneyFormatter(locale, currency, decimals).format(value)
}

// Converts a string to lowercase
const lowerCase = value => value?.toString().toLowerCase() || ''

// Converts a string to PascalCase
const pascalCase = value =>
  value
    .match(/[a-z]+/gi)
    ?.map(word => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
    .join('') || ''

// Converts a string to snake_case
const snakeCase = value =>
  value
    ?.toString()
    .replace(/([A-Z])/g, '_$1')
    .toLowerCase() || ''

// Converts a string to UPPERCASE
const upperCase = value => value?.toString().toUpperCase() || ''

const removeUnderscores = value => value?.toString().replace(/_/g, ' ') || ''

// Converts a string to kebab-case
const kebabCase = value =>
  value
    ?.toString()
    .replace(/([A-Z])/g, '-$1')
    .toLowerCase() || ''

// Capitalizes the first letter of a string
const capitalize = value => (value ? value.charAt(0).toUpperCase() + value.slice(1) : '')

// Generates an MD5 hash of a string
const hashMd5 = value => md5(value)
const isEmpty = value => lodashIsEmpty(value)

const hashStringToNumber = (str) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
    hash = hash & hash // Convert to 32bit integer
  }
  return Math.abs(hash)
}

const formatDecimal = (value, decimals = 2) => {
  return parseFloat(value).toFixed(decimals)
}

// Used to get the correct translation from a json string returned because of the usage of spatie/laravel-translatable
const getTranslation = (input, locale = 'en') => {
  const translation = JSON.parse(input)

  return translation[locale] || translation.en || '-'
}

const countTokens = (text) => {
  const tokens = encode(text)
  return tokens.length
}

const markdownToHtml = (text) => {
  return marked(text)
}

const objectToStructure = (obj) => {
    if (obj === null) return null
    if (typeof obj !== 'object') return typeof obj
    
    if (Array.isArray(obj)) {
      return obj.length ? [objectToStructure(obj[0])] : []
    }
    
    const result = {}
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        result[key] = objectToStructure(obj[key])
      }
    }

    return result
}

const prettyJson = (obj) => {
  return JSON.stringify(obj, null, 2)
}

export {
  moneyFormat,
  lowerCase,
  pascalCase,
  snakeCase,
  upperCase,
  removeUnderscores,
  kebabCase,
  capitalize,
  hashMd5,
  isEmpty,
  hashStringToNumber,
  formatDecimal,
  getTranslation,
  countTokens,
  markdownToHtml,
  objectToStructure,
  prettyJson
}
