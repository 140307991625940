import * as filters from '@/bootstrap/filters'

export default {
  install(app) {
    const $filters = Object.assign({}, filters) // Optimized assignment

    // Assign the $filters object to Vue's global properties
    app.config.globalProperties.$filters = $filters
  }
}
