<template>
  <Suspense>
    <template #default>
      <component :is="layout">
        <RouterView />
      </component>
    </template>
    <template #fallback>
      <LoadingSegment />
    </template>
  </Suspense>
</template>

<script setup>
import {computed, defineAsyncComponent, ref, onMounted} from 'vue'
import {RouterView} from 'vue-router'
import {storeImports} from '@/stores/storeImports'
import LoadingSegment from './views/Layouts/Partials/LoadingSegment.vue'

const GuestLayout = defineAsyncComponent(
  () => import('./views/Layouts/GuestLayout.vue' /* @vite-ignore */)
)
const AuthenticatedLayout = defineAsyncComponent(
  () => import('./views/Layouts/AuthenticatedLayout.vue' /* @vite-ignore */)
)

const userStore = ref(null)
const layout = computed(() =>
  userStore.value?.isAuthenticated ? AuthenticatedLayout : GuestLayout
)

onMounted(async () => {
  userStore.value = await storeImports.user()
})
</script>
