<template>
  <div class="sidebar">
    <div class="action-buttons">
      <div class="ui buttons fluid">
        <button
          class="ui button"
          :class="{active: currentViewType === 'single'}"
          :disabled="disabled"
          @click="$emit('fetchSingle')"
          >Single file</button
        >

        <button
          class="ui button"
          :class="{active: currentViewType === 'stepRepeat'}"
          :disabled="disabled"
          @click="$emit('fetchStepRepeat')"
          >S&amp;R file</button
        >
      </div>
    </div>

    <div
      ref="scrollableRef"
      class="scrollable"
    >
      <div class="ui fluid styled accordion">
        <template
          v-for="(item, index) in accordionItems"
          :key="index"
        >
          <div
            :class="['title', {active: activeIndex === index}]"
            @click="toggleAccordion(index)"
          >
            <i class="dropdown icon"></i>
            {{ item.title }}
          </div>
          <div :class="['content', {active: activeIndex === index}]">
            <div>
              <p
                v-for="n in 30"
                :key="n"
                >{{ item.content }} {{ n }}</p
              >
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, nextTick, watch, defineProps, defineEmits} from 'vue'

defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
  currentViewType: {
    // Add prop to receive current view type
    type: String,
    required: true,
    validator: value => ['single', 'stepRepeat'].includes(value)
  }
})
const _emit = defineEmits(['fetchSingle', 'fetchStepRepeat'])

const activeIndex = ref(0) // Start with the first item open
const scrollableRef = ref(null)

const accordionItems = ref([
  {title: 'Section 1', content: 'Content for section 1.'},
  {title: 'Section 2', content: 'Content for section 2.'},
  {title: 'Section 3', content: 'Content for section 3.'}
])

const toggleAccordion = index => {
  activeIndex.value = activeIndex.value === index ? null : index
  // Scroll to bottom after toggling to ensure content is visible
  nextTick(() => {
    scrollToBottom()
  })
}

const scrollToBottom = () => {
  if (!scrollableRef.value) return

  nextTick(() => {
    const container = scrollableRef.value
    container.scrollTop = container.scrollHeight
  })
}

// Check if element is scrollable (has overflow)
const ensureScrollable = () => {
  if (!scrollableRef.value) return

  const container = scrollableRef.value
  // Force layout recalculation
  container.style.display = 'none'

  container.offsetHeight // Force reflow
  container.style.display = ''
}

watch(
  activeIndex,
  () => {
    ensureScrollable()

    setTimeout(scrollToBottom, 50)
  },
  {flush: 'post'}
)

onMounted(() => {
  ensureScrollable()
  scrollToBottom()
})
</script>

<style lang="scss" scoped>
.sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;

  .action-buttons {
    flex: 0 0 auto;
    padding: 1rem;
    background-color: white;
    z-index: 2;
    position: sticky;
    top: 0;
  }

  .scrollable {
    flex: 1 1 auto;
    overflow-y: auto !important;
    max-height: calc(100vh - 70px); /* Adjust based on your action-buttons height */
    position: relative;
    padding-bottom: 1rem;

    .ui.accordion {
      margin-bottom: 70px;
      width: 100%;
    }

    .content.active {
      display: block !important;
      overflow: visible;
      height: auto !important;
    }
  }
}
</style>
