/**
 * Handles redirect to the intended path after authentication and route registration
 * @param {Object} router - Vue Router instance
 */
export function handlePostAuthRedirect(router) {
  // Check if we need to redirect after authentication
  const intendedPath = localStorage.getItem('auth_redirect_path');
  
  if (intendedPath) {
    // eslint-disable-next-line no-console
    console.log('[RedirectHandler] Found intended path in localStorage:', intendedPath);
    
    // Check if we have a flag indicating the redirect is already being handled
    if (window.__redirectBeingHandled) {
      // eslint-disable-next-line no-console
      console.log('[RedirectHandler] Redirect is already being handled, skipping');
      return;
    }
    
    // Set flag to indicate we're handling the redirect
    window.__redirectBeingHandled = true;

    // eslint-disable-next-line no-console
    console.log('[RedirectHandler] Redirecting to:', intendedPath);
    
    // First check if the route exists in the router
    let routeExists = false;
    
    try {
      // Try to resolve the route to check if it exists
      const route = router.resolve(intendedPath);
      routeExists = route && route.matched && route.matched.length > 0;
      
      // eslint-disable-next-line no-console
      console.log('[RedirectHandler] Route exists check:', routeExists, route);
    } catch (e) {
      // If there's an error resolving the route, assume it doesn't exist
      // eslint-disable-next-line no-console
      console.warn('[RedirectHandler] Error checking route:', e);
      routeExists = false;
    }
    
    // Use setTimeout to ensure router is fully initialized
    // This works more reliably than nextTick in this scenario
    setTimeout(() => {
      // eslint-disable-next-line no-console
      console.log('[RedirectHandler] Executing redirect now');
      
      try {
        // Store the path temporarily in case we need to retry
        const pathToNavigate = intendedPath;
        
        // Clear the stored path before navigation
        localStorage.removeItem('auth_redirect_path');
        
        if (routeExists) {
          // If the route exists in the router, use router navigation
          // eslint-disable-next-line no-console
          console.log('[RedirectHandler] Using router navigation to:', pathToNavigate);
          router.push(pathToNavigate).catch(err => {
            // If router navigation fails, fall back to direct navigation
            // eslint-disable-next-line no-console
            console.warn('[RedirectHandler] Router navigation failed, using direct navigation:', err);
            window.location.href = pathToNavigate;
          });
        } else {
          // If the route doesn't exist in the router, use direct navigation
          // eslint-disable-next-line no-console
          console.log('[RedirectHandler] Using direct navigation to:', pathToNavigate);
          window.location.href = pathToNavigate;
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('[RedirectHandler] Navigation error:', err);
        // Fallback to dashboard if redirect fails
        window.location.href = '/dashboard';
      } finally {
        // Clean up the flag after a delay to prevent race conditions
        setTimeout(() => {
          delete window.__redirectBeingHandled;
        }, 500);
      }
    }, 300);
  } else {
    // eslint-disable-next-line no-console
    console.log('[RedirectHandler] No intended path found');
  }
}
