<template>
  <div class="approval-page">
    <div class="sidebar-container">
      <sidebar
        :disabled="isLoading || !!error"
        :current-view-type="currentViewType"
        @fetch-single="handleFetchSingle"
        @fetch-step-repeat="handleFetchStepRepeat"
      />
    </div>
    <div class="viewer-container">
      <div v-if="isLoading">Loading document...</div>
      <div
        v-else-if="error"
        class="error-message"
        >{{ error }}</div
      >
      <viewer
        v-else
        :viewer-url="viewerUrl"
      />
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, onUnmounted, watch} from 'vue' // Add onUnmounted and watch
import {useDocumentVisibility} from '@vueuse/core' // For visibility detection
import {useRoute, useRouter} from 'vue-router' // Import useRouter
import Sidebar from './Partials/Sidebar.vue'
import Viewer from './Partials/Viewer.vue'

import '@/vendor/fomantic/dist/components/button.min.css'
import '@/vendor/fomantic/dist/components/accordion.min.css'
import '@/vendor/fomantic/dist/components/transition.min.css'
import '@/vendor/fomantic/dist/components/loader.min.css'
import '@/vendor/fomantic/dist/components/form.min.css'

const route = useRoute()
const router = useRouter() // Initialize router
const signature = ref('')
const viewerUrl = ref('')
const isLoading = ref(true)
const error = ref(null)
const currentViewType = ref('single') // Track current view type, default to 'single'

const visibility = useDocumentVisibility() // Get visibility state
let pollingInterval = null // To store interval ID
const approvalData = ref(null) // To store fetched data (e.g., notes)

// Modified to accept an optional type ('single' or 'stepRepeat')
const fetchDocumentUrl = async (sig, type) => {
  // Removed default type here
  isLoading.value = true
  error.value = null
  // eslint-disable-next-line no-console
  console.log(`Fetching document for signature: ${sig}, type: ${type}`) // Log type
  try {
    // Placeholder: Replace with actual API call based on signature and type
    // You'll need logic here to determine the correct URL based on 'type'
    let urlToLoad = ''
    if (type === 'single') {
      // Example URL for single
      urlToLoad = `https://proofscope-ext.volkers-connect.nl/portal.cgi?proofscope&url=cloudflow%3A%2F%2FPP_FILE_STORE%2FHAK%2FHAK000068%2FHAK000068%2520SINGLE.pdf&topbar=true&sig=${sig}`
    } else {
      // Assuming 'stepRepeat'
      // Example URL for stepRepeat
      urlToLoad = `https://proofscope-ext.volkers-connect.nl/portal.cgi?proofscope&url=cloudflow%3A%2F%2FPP_FILE_STORE%2FHAK%2FHAK000068%2FHAK000068%2520STEP_REPEAT.pdf&topbar=true&sig=${sig}`
    }
    // Simulate network delay
    await new Promise(resolve => setTimeout(resolve, 300))
    viewerUrl.value = urlToLoad
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Failed to fetch document URL:', err)
    error.value = `Failed to load the ${type} document. Please try again.`
  } finally {
    isLoading.value = false
  }
}

// --- Polling Logic ---

// Function to fetch updated approval data (placeholder)
const fetchApprovalUpdates = async () => {
  if (!signature.value) return // Don't fetch if signature is missing

  // eslint-disable-next-line no-console
  console.log(`Polling for updates for signature: ${signature.value}...`)
  try {
    // Replace with your actual API call
    // const response = await apiService.get(`/approvals/${signature.value}/updates`);
    // approvalData.value = response.data;

    // Placeholder simulation
    await new Promise(resolve => setTimeout(resolve, 500))
    const newData = {
      notes: `Updated notes at ${new Date().toLocaleTimeString()}`,
      timestamp: Date.now()
    }
    approvalData.value = newData
    // eslint-disable-next-line no-console
    console.log('Fetched updates:', newData)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Polling failed:', err)
    // Optionally handle polling errors, maybe stop polling after too many failures
  }
}

// Watch for visibility changes
watch(visibility, isVisible => {
  if (isVisible) {
    // eslint-disable-next-line no-console
    console.log('Tab became visible, fetching updates immediately.')
    fetchApprovalUpdates() // Fetch immediately when tab becomes visible
  } else {
    // eslint-disable-next-line no-console
    console.log('Tab became hidden, polling paused.')
  }
})

// Modify onMounted to start polling
onMounted(() => {
  const sig = route.params.signature
  if (!sig) {
    error.value = 'No signature provided in the URL.'
    isLoading.value = false
    return
  }
  signature.value = sig

  // Read initial view type from URL query, default to 'single'
  const initialType = route.query.view === 'stepRepeat' ? 'stepRepeat' : 'single'
  currentViewType.value = initialType

  // Fetch initial document based on URL or default
  fetchDocumentUrl(signature.value, currentViewType.value)

  // Ensure URL reflects the initial state if not already set
  if (!route.query.view || route.query.view !== currentViewType.value) {
    updateUrlQuery(currentViewType.value)
  }

  // Start polling only if tab is initially visible
  if (visibility.value) {
    fetchApprovalUpdates() // Initial fetch
  }
  pollingInterval = setInterval(() => {
    if (visibility.value) {
      // Only poll if tab is visible
      fetchApprovalUpdates()
    }
  }, 10000) // Poll every 10 seconds
})

// Clear interval on unmount
onUnmounted(() => {
  if (pollingInterval) {
    clearInterval(pollingInterval)

    // eslint-disable-next-line no-console
    console.log('Polling interval cleared.')
  }
})

// --- End Polling Logic ---

// Function to update URL query parameter without reloading page
const updateUrlQuery = type => {
  router.replace({query: {...route.query, view: type}})
}

onMounted(() => {
  const sig = route.params.signature

  if (!sig) {
    error.value = 'No signature provided in the URL.'
    isLoading.value = false
    return
  }
  signature.value = sig

  // Read initial view type from URL query, default to 'single'
  const initialType = route.query.view === 'stepRepeat' ? 'stepRepeat' : 'single'
  currentViewType.value = initialType

  // Fetch initial document based on URL or default
  fetchDocumentUrl(signature.value, currentViewType.value)

  // Ensure URL reflects the initial state if not already set
  if (!route.query.view || route.query.view !== currentViewType.value) {
    updateUrlQuery(currentViewType.value)
  }
})

// Handler for the 'Single' button click
const handleFetchSingle = () => {
  if (!signature.value || currentViewType.value === 'single') return // Avoid refetch if already active
  currentViewType.value = 'single'
  updateUrlQuery('single')
  fetchDocumentUrl(signature.value, 'single')
}

// Handler for the 'Step and repeat' button click
const handleFetchStepRepeat = () => {
  if (!signature.value || currentViewType.value === 'stepRepeat') return // Avoid refetch if already active
  currentViewType.value = 'stepRepeat'
  updateUrlQuery('stepRepeat')
  fetchDocumentUrl(signature.value, 'stepRepeat')
}
</script>

<style lang="scss" scoped>
.approval-page {
  display: grid;
  grid-template-columns: 4fr 12fr;
  height: calc(
    100vh - var(--header-height, 0px)
  ); // Use CSS variable for header height, fallback to 0px
  margin: 0;
  overflow: hidden;

  .sidebar-container {
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;

    :deep(.sidebar) {
      flex: 1;
      margin: 0;
      border-radius: 0;
      border: none;
      border-right: 1px solid #ddd;
    }
  }

  .viewer-container {
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .error-message {
      color: red;
      padding: 20px;
      text-align: center;
    }

    :deep(iframe),
    :deep(.viewer-component-root) {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
