// src/plugins/cachePlugin.js

/**
 * Cache class for managing data storage with support for different storage types.
 */
class Cache {
  /**
   * Creates an instance of Cache.
   * @param {string} storage - The type of storage to use ('memory', 'localStorage', 'sessionStorage').
   * @param {string} prefix - The prefix to use for storage keys.
   */
  constructor(storage = 'memory', prefix = 'cache_') {
    this.defaultPrefix = prefix
    this.setStorage(storage)
    this.tagMap = new Map() // Initialize tag-to-keys mapping
  }

  /**
   * Sets the storage type based on the provided storageType.
   * @param {string} storageType - The storage type to set.
   */
  setStorage(storageType) {
    switch (storageType) {
      case 'localStorage':
        this.storage = window.localStorage
        break
      case 'sessionStorage':
        this.storage = window.sessionStorage
        break
      case 'memory':
      default:
        this.storage = new Map()
        break
    }
  }

  /**
   * Generates a standardized key for storage.
   * @param {string} key - The key to be stored.
   * @returns {string} - The formatted key.
   */
  _getKey(key) {
    return `${this.defaultPrefix}${key}`
  }

  /**
   * Retrieves data from the storage based on the key.
   * @param {string} key - The key of the data to retrieve.
   * @returns {any} - The retrieved data or null if not found.
   */
  _getFromStorage(key) {
    if (this.storage instanceof Map) {
      return this.storage.get(key)
    }
    const item = this.storage.getItem(this._getKey(key))
    return item ? JSON.parse(item) : null
  }

  /**
   * Stores data in the storage with the given key.
   * @param {string} key - The key under which the data is stored.
   * @param {any} data - The data to store.
   * @param {Array<string>} [tags=[]] - Tags associated with the cached data.
   */
  _setToStorage(key, data, tags = []) {
    if (this.storage instanceof Map) {
      this.storage.set(key, data)
    }
    else {
      this.storage.setItem(this._getKey(key), JSON.stringify(data))
    }

    if (tags.length > 0) {
      tags.forEach((tag) => {
        if (!this.tagMap.has(tag)) {
          this.tagMap.set(tag, new Set())
        }
        this.tagMap.get(tag).add(key)
      })
    }
  }

  /**
   * Removes data from the storage based on the key.
   * @param {string} key - The key of the data to remove.
   */
  _removeFromStorage(key) {
    if (this.storage instanceof Map) {
      this.storage.delete(key)
    }
    else {
      this.storage.removeItem(this._getKey(key))
    }

    const entry = this._getFromStorage(key)
    if (entry && entry.tags) {
      entry.tags.forEach((tag) => {
        const keys = this.tagMap.get(tag)
        if (keys) {
          keys.delete(key)
          if (keys.size === 0) {
            this.tagMap.delete(tag)
          }
        }
      })
    }
  }

  /**
   * Retrieves data from the cache or fetches it using the provided fetcher function.
   * @param {string} key - The key of the data.
   * @param {Function} fetcher - The function to fetch data if not present in cache.
   * @param {number} [ttl=0] - Time-to-live for the cached data in milliseconds.
   * @param {Array<string>} [tags=[]] - Tags associated with the cached data.
   * @returns {Promise<any>} - The cached or fetched data.
   */
  async remember(key, fetcher, ttl = 0, tags = []) {
    const now = Date.now()
    const entry = this._getFromStorage(key)

    if (entry && (entry.expiry === 0 || entry.expiry > now)) {
      return entry.data
    }

    try {
      const data = await fetcher()
      this._setToStorage(key, {
        data,
        expiry: ttl ? now + ttl : 0,
        tags
      }, tags)
      return data
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Cache fetch error for key "${key}":`, error)
      throw error
    }
  }

  /**
   * Invalidates cache entries associated with the specified tag.
   * @param {string} tag - The tag based on which cache entries are invalidated.
   */
  invalidateByTag(tag) {
    const keys = this.tagMap.get(tag)
    if (keys) {
      keys.forEach((key) => {
        this._removeFromStorage(key)
      })
      this.tagMap.delete(tag)
    }
  }

  /**
   * Clears all cache entries.
   */
  clearAll() {
    if (this.storage instanceof Map) {
      this.storage.clear()
    }
    else {
      Object.keys(this.storage).forEach((key) => {
        if (key.startsWith('cache_')) {
          this.storage.removeItem(key)
        }
      })
    }
  }
}

// Vue plugin
export default {
  /**
   * Installs the cache plugin into the Vue application.
   * @param {App} app - The Vue application instance.
   * @param {Object} [options={}] - Configuration options for the cache.
   */
  install(app, options = {}) {
    const { storage = 'memory' } = options
    const cacheInstance = new Cache(storage)

    // Make the cache instance globally available
    app.config.globalProperties.$cache = cacheInstance

    // Provide a Composition API-friendly way to access the cache
    app.provide('cache', cacheInstance)
  }
}
