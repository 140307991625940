import axios from 'axios'

const backendApiClient = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Referrer': import.meta.env.VITE_BACKEND_BASE_URL
  }
})

backendApiClient.interceptors.response.use(
  response => response,
  (error) => {
    // eslint-disable-next-line no-console
    console.error('API Error:', error)

    return Promise.reject(error)
  }
)

export default backendApiClient
