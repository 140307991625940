<template>
  <div
    id="loading-segment"
    class="ui segment"
  >
    <div class="ui active inverted dimmer">
      <div class="ui text loader"> Loading </div>
    </div>
  </div>
</template>
