export { storeImports } from './stores/storeImports'
export { 
  initializeStores, 
  getSystemStore, 
  getUserStore, 
  resetStoreInstances 
} from './stores/storeBootstrap'

export const appSettings = {
  name: import.meta.env.VITE_APP_NAME,
  baseUrl: import.meta.env.BASE_URL,
  mode: import.meta.env.MODE,
  ssr: import.meta.env.SSR
}

export const backendSettings = {
  baseUrl: import.meta.env.VITE_BACKEND_BASE_URL
}
