import * as msal from '@azure/msal-browser'

const AUTHORITY_URL = 'https://login.microsoftonline.com/8fb21dc3-210e-4411-9712-a987f5b0cba3'

let msalApp
let isInitialized = false
let initPromise = null

export default {
  configure(clientId) {
    if (initPromise) {
      return initPromise
    }

    if (!clientId) {
      // eslint-disable-next-line no-console
      console.warn('Azure AD sign-in is disabled. Running in demo mode.')
      return Promise.resolve(null)
    }

    const config = {
      auth: {
        clientId: clientId,
        redirectUri: window.location.origin,
        authority: AUTHORITY_URL
      },
      cache: {
        cacheLocation: 'sessionStorage'
      },
      system: {
        loggerOptions: {
          logLevel: msal.LogLevel.Error
        }
      }
    }

    // Create a new instance of PublicClientApplication
    msalApp = new msal.PublicClientApplication(config)
    
    // Initialize the MSAL application and store the promise
    initPromise = msalApp.initialize().then(() => {
      isInitialized = true
      // eslint-disable-next-line no-console
      console.log('Azure AD sign-in is enabled and initialized')
      return msalApp
    }).catch(error => {
      // eslint-disable-next-line no-console
      console.error('Failed to initialize MSAL:', error)
      isInitialized = false
      initPromise = null
      throw error
    })

    return initPromise
  },
  
  // Ensure the library is initialized before any operation
  ensureInitialized() {
    if (isInitialized && msalApp) {
      return msalApp
    }
    
    if (initPromise) {
      return initPromise
    }
    
    throw new Error('MSAL not initialized. Call configure() first.')
  },
  
  // Handle the redirect response if we have one
  async handleRedirectPromise() {
    try {
      await this.ensureInitialized()
      
      // Check if we have response from redirect
      const response = await msalApp.handleRedirectPromise()
      if (response) {
        // eslint-disable-next-line no-console
        console.info('Redirect response processed:', response.account?.username)
        return response
      }
      return null
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error handling redirect:', err)
      throw err
    }
  },

  clientId() {
    return msalApp ? msalApp.config.auth.clientId : null
  },

  async login(scopes = ['user.read']) {
    try {
      await this.ensureInitialized()
      
      // Get active account if exists
      const accounts = msalApp.getAllAccounts()
      if (accounts.length > 0) {
        // Already logged in, return the account
        return accounts[0]
      }
      
      // Start login process
      await msalApp.loginRedirect({
        scopes,
        prompt: 'select_account'
      })
      // Return null since redirect will navigate away
      return null
    }
    catch (err) {
      // eslint-disable-next-line no-console
      console.error('Login failed:', err)
      throw err
    }
  },

  async acquireToken(scopes = ['user.read']) {
    try {
      await this.ensureInitialized()
      
      const accounts = msalApp.getAllAccounts()
      if (accounts.length === 0) {
        // No accounts, need to login first
        throw new Error('No authenticated accounts found. Please login first.')
      }

      const accessTokenRequest = {
        scopes,
        account: accounts[0] // Use first account
      }

      try {
        const tokenResp = await msalApp.acquireTokenSilent(accessTokenRequest)
        return tokenResp.accessToken
      }
      catch (err) {
        // eslint-disable-next-line no-console
        console.warn('Silent token acquisition failed, trying popup:', err)

        try {
          const tokenResp = await msalApp.acquireTokenPopup(accessTokenRequest)
          return tokenResp.accessToken
        } catch (popupError) {
          // eslint-disable-next-line no-console
          console.error('Popup token acquisition failed:', popupError)
          throw popupError
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Token acquisition failed:', err)
      throw err
    }
  },

  async logout() {
    try {
      await this.ensureInitialized()
      const logoutRequest = {
        account: this.user(),
        postLogoutRedirectUri: window.location.origin
      }
      msalApp.logoutRedirect(logoutRequest)
    } catch {
      // eslint-disable-next-line no-console
      console.error('Logout failed')
      throw new Error('Logout failed')
    }
  },

  async user() {
    try {
      await this.ensureInitialized()
      return msalApp.getAllAccounts()?.[0] || null
    } catch {
      return null
    }
  }
}
