import { reactive } from 'vue'
import tokens from '@/assets/tokens.json'

export function useDesignTokens() {
  const designTokens = reactive(tokens)

  const getColorHex = (colorPath) => {
    const pathParts = colorPath.split('.')
    let color = { ...designTokens.colors }
    
    for (const part of pathParts) {
      if (!color[part]) return null
      color = color[part]
    }
    
    // Handle referenced colors (like signalColor: "sunshineYellow")
    if (typeof color === 'string') {
      return getColorHex(`primary.${color}`)
    }
    
    return color.hex || null
  }

  const getColorRgb = (colorPath) => {
    const pathParts = colorPath.split('.')
    let color = { ...designTokens.colors }
    
    for (const part of pathParts) {
      if (!color[part]) return null
      color = color[part]
    }
    
    // Handle referenced colors
    if (typeof color === 'string') {
      return getColorRgb(`primary.${color}`)
    }
    
    return color.rgb || null
  }

  const getFontFamily = (fontKey) => {
    return designTokens.typography[fontKey] || null
  }

  const getFontWeight = (weightKey) => {
    return designTokens.typography.weights[weightKey] || null
  }

  return {
    tokens: designTokens,
    getColorHex,
    getColorRgb,
    getFontFamily,
    getFontWeight
  }
}