import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/components/Authentication/Login.vue'
import ApprovalPage from '@/views/Approval/ApprovalPage.vue'
import authenticatedRoutes from './routes/authenticated'
import { storeImports } from '@/stores/storeImports'
import { appSettings } from '@/main'

// Shared route definition for approval page
const approvalRoute = {
  name: 'approval',
  path: '/approve/:signature',
  component: ApprovalPage,
  meta: {
    title: 'Approval'
    // No 'auth' middleware needed here, accessible by guests and authenticated users
  }
};

// -----------------------------------------------------------------------------
// 1. Unauthenticated Routes
// -----------------------------------------------------------------------------
function createUnauthenticatedRoutes() {
  return [
    {
      name: 'login',
      path: '/',
      component: Login,
      meta: {
        title: 'Login'
      },
      beforeEnter: (to, from, next) => {
        // Check if there's a redirect path in localStorage
        const redirectPath = localStorage.getItem('auth_redirect_path');
        if (redirectPath) {
          // eslint-disable-next-line no-console
          console.log('[Router] Login route has redirect path:', redirectPath);
        }
        next();
      }
    },
    approvalRoute, // Use the shared route object
    {
      // Catch-all route redirects to login but saves the intended path
      path: '/:pathMatch(.*)*',
      redirect: (to) => {
        // Store the full path in localStorage
        const path = to.fullPath;
        
        // eslint-disable-next-line no-console
        console.log('[Router] 🔴 Unauthenticated access attempt to:', path);
        
        // Only store non-login paths
        if (path !== '/' && !path.includes('/login')) {
          // Store the path in localStorage for redirect after login
          localStorage.setItem('auth_redirect_path', path);
          
          // eslint-disable-next-line no-console
          console.log('[Router] ✅ Stored intended path in localStorage:', path);
          
          // Also set a flag in window to indicate we have a redirect path
          window.__hasRedirectPath = true;
        }
        
        return '/';
      }
    }
  ]
}

// -----------------------------------------------------------------------------
// 2. Authenticated Routes (Including Dynamic Routes from resourceModels)
// -----------------------------------------------------------------------------

// Cache management
const routerCache = {
  authenticated: null,
  systemStore: null,
  timestamp: null,
  invalidate() {
    this.authenticated = null;
    this.systemStore = null;
    this.timestamp = null;
  },
  isValid() {
    return this.timestamp && (Date.now() - this.timestamp < 3600000); // 1 hour
  }
};

async function createAuthenticatedRoutes() {
  if (routerCache.isValid() && routerCache.authenticated) {
    // eslint-disable-next-line no-console
    console.log('[Router] Using cached authenticated routes');
    return routerCache.authenticated;
  }

  // eslint-disable-next-line no-console
  console.log('[Router] Creating authenticated routes');

  if (!routerCache.systemStore) {
    // eslint-disable-next-line no-console
    console.log('[Router] Loading system store');
    routerCache.systemStore = await storeImports.system();
  }
  
  const systemStore = routerCache.systemStore;

  // Ensure systemStore has loaded data
  if (!systemStore || !Array.isArray(systemStore.resourceModels) || systemStore.resourceModels.length === 0) {
    try {
      // eslint-disable-next-line no-console
      console.log('[Router] System data not loaded, fetching now');
      await systemStore.fetchSystemData();
      
      // Double-check that we have resource models after fetching
      if (!Array.isArray(systemStore.resourceModels) || systemStore.resourceModels.length === 0) {
        // eslint-disable-next-line no-console
        console.warn('[Router] Still no resource models after fetch, using static routes only');
        // Include approvalRoute even in fallback
        return [...authenticatedRoutes, approvalRoute];
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('[Router] Error fetching system data:', error);
      // Include approvalRoute even in error fallback
      return [...authenticatedRoutes, approvalRoute];
    }
  }

  // eslint-disable-next-line no-console
  console.log('[Router] Creating routes with', systemStore.resourceModels.length, 'resource models');

  const validResourceModels = systemStore.resourceModels.filter(model =>
    model && typeof model === 'object' && model.table_name && model.slug
  );

  // eslint-disable-next-line no-console
  console.log('[Router] Found', validResourceModels.length, 'valid resource models');

  const dynamicRoutes = validResourceModels.flatMap(createRoutesForModel);
  // Add the shared approval route to the authenticated routes as well
  routerCache.authenticated = [...authenticatedRoutes, ...dynamicRoutes, approvalRoute];
  routerCache.timestamp = Date.now();
  
  // eslint-disable-next-line no-console
  console.log('[Router] Created', routerCache.authenticated.length, 'total authenticated routes');
  
  return routerCache.authenticated;
}

// -----------------------------------------------------------------------------
// 3. Router Factory
// -----------------------------------------------------------------------------
function createAppRouter(allRoutes) {
  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: allRoutes,
    linkActiveClass: 'active',
    // Add strict path matching to avoid issues with trailing slashes
    strict: true
  })

  // After each route, set page title using appSettings + meta.title
  router.afterEach((to) => {
    const appName = appSettings.name || 'Default App Name'
    const pageTitle = to.meta.title
    document.title = pageTitle ? `${appName} | ${pageTitle}` : appName
  })
  
  // Add navigation debugging
  router.beforeEach((to, from) => {
    // eslint-disable-next-line no-console
    console.log(`[Router] Navigation from "${from.fullPath}" to "${to.fullPath}"`);

    return true;
  });

  return router
}

// -----------------------------------------------------------------------------
// 4. Helpers to Create Dynamic Routes for Each Resource Model
// -----------------------------------------------------------------------------
const routeComponents = {
  index: () => import(/* webpackChunkName: "crud-index" */ '@/views/Search/Index.vue'),
  create: () => import(/* webpackChunkName: "crud-create-edit" */ '@/views/CRUD/CreateEdit/Index.vue'),
  edit: () => import(/* webpackChunkName: "crud-create-edit" */ '@/views/CRUD/CreateEdit/Index.vue'),
  show: () => import(/* webpackChunkName: "crud-show" */ '@/views/CRUD/Show/Index.vue')
}

const routePaths = {
  index: slug => `/${slug}/index`,
  create: slug => `/${slug}/create`,
  edit: slug => `/${slug}/:id/edit`,
  show: slug => `/${slug}/:id`
}

function createRoutesForModel(resourceModel) {
  if (!resourceModel || !resourceModel.table_name) {
    return []
  }
  
  const routes = []
  const actions = ['index', 'create', 'edit', 'show']

  actions.forEach((action) => {
    if (resourceModel[`crud_${action}`]) {
      routes.push(createRoute(resourceModel, action))
    }
  })

  return routes
}

function createRoute(resourceModel, type) {
  const { tableName, slug, displayNameSingular, displayNamePlural } = {
    tableName: resourceModel.table_name,
    slug: resourceModel.slug,
    displayNameSingular: resourceModel.display_name_singular,
    displayNamePlural: resourceModel.display_name_plural
  }
  const resourceName = type === 'index' 
    ? (displayNamePlural || slug).toLowerCase()
    : (displayNameSingular || slug).toLowerCase()
  const action = type.charAt(0).toUpperCase() + type.slice(1)
  return {
    name: `${tableName}.${type}`,
    path: routePaths[type](slug),
    component: routeComponents[type],
    meta: {
      title: `${action} ${resourceName}`,
      middleware: ['auth'],
      can: [`${tableName}-${type}`]
    },
    props: route => ({
      resourceModel,
      ...(type === 'index' && { keyword: route.query.keyword }),
      ...((type === 'edit' || type === 'show') && { modelId: route.params.id })
    })
  }
}

// -----------------------------------------------------------------------------
// 5. Optional: One-shot to create the “full” router in your main entry point
// -----------------------------------------------------------------------------
export async function createFullRouter() {
  const unauthenticated = createUnauthenticatedRoutes()
  const authenticated = await createAuthenticatedRoutes()
  const allRoutes = [...unauthenticated, ...authenticated]

  return createAppRouter(allRoutes)
}

export {
  createAppRouter,
  createUnauthenticatedRoutes,
  createAuthenticatedRoutes
}
