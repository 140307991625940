import axios from 'axios'
import auth from './azureAuth'

const GRAPH_BASE = 'https://graph.microsoft.com/v1.0/'
const GRAPH_SCOPES = ['user.read', 'user.readbasic.all']

let accessToken

export default {
  // Get details of user, and return as JSON
  // https://docs.microsoft.com/en-us/graph/api/user-get?view=graph-rest-1.0&tabs=http#response-1
  async getProfile() {
    try {
      const resp = await callGraph('/me')
      return resp
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching profile:', error)

      throw error // Rethrow or handle as needed
    }
  },

  // Accessor for access token, only included for demo purposes
  async getAccessToken() {
    try {
      accessToken = await auth.acquireToken(GRAPH_SCOPES)
      return accessToken
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to acquire token:', error)
      throw error
    }
  },

  // Clear token when needed
  clearToken() {
    accessToken = null
  }
}

// Common fetch wrapper (private)
const graphClient = axios.create({
  baseURL: GRAPH_BASE,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

graphClient.interceptors.request.use(
  async (config) => {
    try {
      // Get a new token if we don't have one
      if (!accessToken) {
        accessToken = await auth.acquireToken(GRAPH_SCOPES)
      }
      
      if (!accessToken) {
        throw new Error('Failed to acquire access token for Microsoft Graph')
      }
      
      config.headers.Authorization = `Bearer ${accessToken}`
      return config
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error in request interceptor:', error)
      return Promise.reject(error)
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Handle token expiration
graphClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config
    
    // If 401 error and we haven't retried yet
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      
      try {
        // Clear token and get a new one
        accessToken = null
        accessToken = await auth.acquireToken(GRAPH_SCOPES)
        
        // Update the header and retry
        originalRequest.headers.Authorization = `Bearer ${accessToken}`
        return graphClient(originalRequest)
      } catch (refreshError) {
        // eslint-disable-next-line no-console
        console.error('Token refresh failed:', refreshError)
        return Promise.reject(refreshError)
      }
    }
    
    return Promise.reject(error)
  }
)

async function callGraph(apiPath) {
  try {
    const resp = await graphClient.get(apiPath)

    if (resp.status !== 200) {
      throw new Error(`Call to ${apiPath} failed: ${resp.statusText}`)
    }

    return resp.data
  } catch (error) {
    // Check if connection error or API error
    if (!error.response) {
      // Network error
      throw new Error('Unable to connect to Microsoft Graph. Please check your network connection.')
    } else {
      // API error
      throw new Error(`Microsoft Graph API error (${error.response.status}): ${error.response.data?.error?.message || error.message}`)
    }
  }
}
