const authenticatedRoutes = [
  {
    name: 'authenticated',
    path: '/',
    redirect: '/dashboard',
    meta: {
      middleware: 'auth',
      transition: { name: 'fade' }
    },
    children: [
      {
        name: 'dashboard',
        alias: '/',
        path: '/dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/Index.vue'),
        meta: {
          title: 'Dashboard',
          middleware: 'auth'
        }
      },
      // User related routes
      {
        name: 'users.profile',
        path: '/users/profile',
        component: () => import(/* webpackChunkName: "users" */ '@/views/Users/Profile.vue'),
        meta: {
          title: 'Profile',
          middleware: 'auth'
        }
      },
      // System related routes
      {
        name: 'system',
        path: '/system',
        component: () => import(/* webpackChunkName: "system" */ '@/views/System/Index.vue'),
        meta: {
          title: 'System',
          middleware: 'auth'
        }
      },
      {
        name: 'system.apitokens',
        path: '/system/api-tokens', // Changed to be nested under /system
        component: () => import(/* webpackChunkName: "system-tokens" */ '@/views/ApiTokens/Index.vue'),
        meta: {
          title: 'API Tokens',
          middleware: 'auth'
        }
      },
      // Planning related routes
      {
        name: 'planning.overview',
        path: '/planning/overview', // Changed for consistency
        component: () => import(/* webpackChunkName: "planning" */ '@/views/Tasks/Planning/Overview.vue'),
        meta: {
          title: 'Planning overview',
          middleware: 'auth'
        }
      },
      {
        name: 'ai.overview',
        path: '/ai/overview',
        component: () => import(/* webpackChunkName: "ai" */ '@/views/AI/Overview.vue'),
        meta: {
          title: 'AI overview',
          middleware: ['auth', 'admin']
        }
      }
    ]
  }
]

export default authenticatedRoutes
