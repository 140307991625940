import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import axios from 'axios'
import { storeImports } from '@/stores/storeImports'

export default {
  install: (app, options = {}) => {
    window.Pusher = Pusher

    axios.interceptors.response.use(
      response => response,
      (error) => {
        if (error.response?.status === 401) {
          app.config.globalProperties.$echo.connector.disconnect()
          
          const userStore = storeImports.user()
          userStore.signOut()
        }

        return Promise.reject(error)
      }
    )

    const echo = new Echo({
      broadcaster: 'pusher',
      key: options.key,
      cluster: options.cluster,
      forceTLS: options.forceTLS ?? true,
      wsHost: options.wsHost ?? null,
      wsPort: options.wsPort ?? null,
      wssPort: options.wssPort ?? null,
      disableStats: true,
      channelAuthorization: {
        customHandler: async (payload, callback) => {
          const { socketId, channelName } = payload;

          try {
            const response = await axios.post(
              options.authEndpoint,
              {
                socket_id: socketId,
                channel_name: channelName
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${options.token}`,
                  'Accept': 'application/json',
                  ...(options.referer
                    ? { Referer: options.referer }
                    : {}
                  )
                }
              }
            );

            callback(null, response.data);
          } catch (error) {
            callback(error, null);
          }
        },
      },
    })

    app.config.globalProperties.$echo = echo
  }
}
