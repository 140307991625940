import { ref, computed, reactive } from 'vue'
import { getUserStore } from '@/stores/storeBootstrap'
import backendApiClient from '@/services/backendApiClient'
import azureAuth from '@/services/azureAuth'
import { useRouter } from 'vue-router'

export const useAuth = () => {
  const router = useRouter()
  const loading = ref(false)
  const errors = ref([])
  
  // Local reactive state for authState to avoid computed async issues
  const authState = reactive({
    user: null,
    isAuthenticated: false
  })
  
  // Initialize auth state immediately
  getUserStore().then(store => {
    authState.isAuthenticated = store.isAuthenticated
    authState.user = store.user
  })
  
  const isAuthenticated = computed(() => authState.isAuthenticated)
  const user = computed(() => authState.user)
  
  const loginWithCredentials = async (credentials) => {
    loading.value = true
    errors.value = []
    
    try {
      const userStore = await getUserStore()
      await userStore.signIn(credentials)
      
      // Update local state
      authState.isAuthenticated = userStore.isAuthenticated
      authState.user = userStore.user
      
      return true
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Invalid credentials.'
      errors.value.push({ message: errorMessage })
      return false
    } finally {
      loading.value = false
    }
  }
  
  const loginWithAzure = async () => {
    loading.value = true
    errors.value = []
    
    try {
      const loginResult = await azureAuth.login()
      
      if (loginResult?.accessToken) {
        const userStore = await getUserStore()
        await userStore.signIn({
          azure_token: loginResult.accessToken
        })
        
        // Update local state
        authState.isAuthenticated = userStore.isAuthenticated
        authState.user = userStore.user
        
        return true
      }
      
      errors.value.push({ message: 'Failed to authenticate with Azure.' })
      return false
    } catch (error) {
      errors.value.push({ message: error.message || 'Failed to authenticate with Azure.' })
      return false
    } finally {
      loading.value = false
    }
  }
  
  const logout = async () => {
    loading.value = true
    
    try {
      const userStore = await getUserStore()
      
      // If we have a token, try to call logout endpoint
      if (userStore.token) {
        try {
          await backendApiClient.post('/api/v1/auth/logout')
        } catch {
          // Continue with local logout even if API call fails
        }
      }
      
      await userStore.clearUserData()
      
      // Update local state
      authState.isAuthenticated = false
      authState.user = {}
      
      // Navigate to login page
      router.push({ name: 'login' })
      
      return true
    } catch { // API failure intentionally ignored for local logout
      errors.value.push({ message: 'Failed to logout. Please try again.' })
      return false
    } finally {
      loading.value = false
    }
  }
  
  // Refresh user data from API
  const refreshUser = async () => {
    try {
      const userStore = await getUserStore()
      await userStore.fetchUser()
      
      // Update local state
      authState.user = userStore.user
      
      return true
    } catch {
      // Error is intentionally ignored for background refresh
      return false
    }
  }
  
  // Check if a user has a specific permission
  const hasPermission = (permissionName) => {
    if (!authState.user?.permissions) return false
    return authState.user.permissions.includes(permissionName)
  }
  
  return {
    loading,
    errors,
    isAuthenticated,
    user,
    loginWithCredentials,
    loginWithAzure,
    logout,
    refreshUser,
    hasPermission
  }
}